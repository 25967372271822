import { h, Fragment } from "preact";
import { t, translate, } from "restaumatic-client";
import { observer } from "mobx-react";
import { useCallback, useEffect, useRef, useState } from "preact/hooks";
import { RadioFieldOption } from "../../components/RadioField";
import { hideModal } from "../../utils/Modals";
import Device from "../../utils/Device";
import PreactModal, { ModalHeader } from "../../components/PreactModal";
import Alert from "../../components/Alert";
import debounce from "../../utils/debounce";
function FulfillmentTimeModal(props) {
    const { id, dataFieldId, modal, onShow, onSubmit, onHidden } = props;
    const hoursListRef = useRef(null);
    const isMobile = Device.screen.isMobileSize();
    const themeName = Skubacz.configuration.theme_name;
    const navigationPageSize = 14;
    const hasNavigation = !modal.isTodayOnly;
    const handleSubmit = () => {
        modal.save();
        if (modal.isSaved) {
            void hideModal(id).then(() => onSubmit());
        }
    };
    const handleShow = () => {
        modal.initialize({ navigationPageSize });
        onShow();
    };
    return (h(PreactModal, { id: id, modalClass: themeName !== "active_menu"
            ? "modal-dialog--full-height modal-dialog--scrollable"
            : "", dataFieldId: dataFieldId, onShow: handleShow, onHidden: onHidden },
        h(ModalHeader, { title: modal.title }),
        h("div", { class: "modal-body u-pt0" },
            h("article", { class: "m-fulfillment-time" },
                hasNavigation &&
                    (isMobile ? (h(MobileDaysList, { hoursListRef: hoursListRef, modal: modal })) : (h(DesktopDaysList, { hoursListRef: hoursListRef, modal: modal }))),
                h(HoursList, { listRef: hoursListRef, modal: modal }))),
        h("div", { class: "modal-footer modal-footer--sticky" },
            h("button", { type: "button", onClick: handleSubmit, class: "btn btn-primary btn-block", "data-field-id": "fulfillmentTime.submit", disabled: !modal.isValid }, translate(t.actions.save)))));
}
const MobileDaysList = observer((props) => {
    const { modal, hoursListRef } = props;
    const handlePrev = () => {
        if (modal.hasPrevItems) {
            modal.getPrev();
        }
    };
    const handleNext = () => {
        if (modal.hasNextItems) {
            modal.getNext();
        }
    };
    return (h(DaysList, { items: modal.dateItems, prevArrowVisible: modal.hasPrevItems, nextArrowVisible: modal.hasNextItems, setActive: (item) => {
            var _a;
            modal.setActiveDate(item);
            (_a = hoursListRef.current) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
        }, isActive: (item) => modal.isActiveDate(item), isConflicting: (item) => modal.isConflictingItem(item), isDisabled: (item) => modal.isDisabledItem(item), onPrev: handlePrev, onNext: handleNext }));
});
const DesktopDaysList = observer((props) => {
    var _a, _b;
    const { modal, hoursListRef } = props;
    const listRef = useRef(null);
    const scrollingDistanceWidthPercentage = 0.8;
    const scrollingDistance = Math.round(((_b = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.offsetWidth) !== null && _b !== void 0 ? _b : 600) * scrollingDistanceWidthPercentage);
    const [prevArrowVisible, setPrevArrowVisible] = useState(modal.hasPrevItems);
    const [nextArrowVisible, setNextArrowVisible] = useState(modal.hasNextItems);
    const setArrowsVisibility = useCallback(() => {
        const container = listRef.current;
        if (container === null) {
            return;
        }
        const distanceFromEdgeToShowArrow = 30;
        const { scrollLeft, scrollWidth, offsetWidth } = container;
        const distanceFromLeft = scrollLeft;
        const distanceFromRight = scrollWidth - (scrollLeft + offsetWidth);
        setPrevArrowVisible(modal.hasPrevItems || distanceFromLeft > distanceFromEdgeToShowArrow);
        setNextArrowVisible(modal.hasNextItems || distanceFromRight > distanceFromEdgeToShowArrow);
    }, [
        listRef,
        setPrevArrowVisible,
        setNextArrowVisible,
        modal.hasPrevItems,
        modal.hasNextItems,
    ]);
    useEffect(() => {
        setTimeout(() => {
            setArrowsVisibility();
        }, 150); // 0.15s is a hardcoded value in boostrap 3 styles for modal fade transition duration
    }, [modal.dateItems, setArrowsVisibility]);
    useEffect(() => {
        const listEl = listRef.current;
        const debouncedScrollHandler = debounce(() => {
            requestAnimationFrame(setArrowsVisibility);
        }, 100);
        listEl === null || listEl === void 0 ? void 0 : listEl.addEventListener("scroll", debouncedScrollHandler);
        return () => {
            listEl === null || listEl === void 0 ? void 0 : listEl.removeEventListener("scroll", debouncedScrollHandler);
        };
    }, [listRef, setArrowsVisibility]);
    const handlePrev = () => {
        const container = listRef.current;
        if (container !== null &&
            container.scrollLeft <= scrollingDistance &&
            modal.hasPrevItems) {
            const prevScrollWidth = container.scrollWidth;
            // Get current items approximate width (NOTICE: this not includes gap / elements' margin)
            let itemsApproxWidth = 0;
            container === null || container === void 0 ? void 0 : container.querySelectorAll(".m-fulfillment-time__slider-item").forEach((el) => (itemsApproxWidth += el.offsetWidth));
            // Get new items
            modal.getPrev();
            setTimeout(() => {
                const scrollLeftAvailable = container.scrollWidth - prevScrollWidth;
                // Fix basic scrolling distance for case when we have eg. only 2 items on the list and scrolling to the left by basic distance will be not correct after adding new elements to the list (we need distance smaller by the value of missing width of the items to fill in the container)
                const fixedScrollingDistance = itemsApproxWidth < container.offsetWidth
                    ? scrollingDistance - (container.offsetWidth - itemsApproxWidth)
                    : scrollingDistance;
                container.scrollTo(scrollLeftAvailable - fixedScrollingDistance, 0);
            });
        }
        else {
            setTimeout(() => container === null || container === void 0 ? void 0 : container.scrollBy(-scrollingDistance, 0));
        }
    };
    const handleNext = () => {
        if (listRef.current !== null &&
            listRef.current.scrollWidth - listRef.current.scrollLeft <=
                scrollingDistance * 2 &&
            modal.hasNextItems) {
            modal.getNext();
        }
        setTimeout(() => { var _a; return (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.scrollBy(scrollingDistance, 0); });
    };
    return (h(DaysList, { listRef: listRef, items: modal.dateItems, prevArrowVisible: prevArrowVisible, nextArrowVisible: nextArrowVisible, setActive: (item) => {
            var _a;
            modal.setActiveDate(item);
            (_a = hoursListRef.current) === null || _a === void 0 ? void 0 : _a.focus({ preventScroll: true });
        }, isActive: (item) => modal.isActiveDate(item), isConflicting: (item) => modal.isConflictingItem(item), isDisabled: (item) => modal.isDisabledItem(item), onPrev: handlePrev, onNext: handleNext }));
});
const DaysList = observer((props) => {
    const { listRef, items, prevArrowVisible, nextArrowVisible, setActive, isActive, isDisabled, isConflicting, onPrev, onNext, } = props;
    return (h("div", { id: "fulfillment-time-days-slider", class: "m-fulfillment-time__slider u-sticky-top", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_date) },
        h("ul", { ref: listRef, class: "m-fulfillment-time__slider-content u-list-unstyled", "aria-orientation": "horizontal" },
            h("li", { class: `m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--prev ${prevArrowVisible ? "is-visible" : ""}`, key: "prev-arrow" },
                h("button", { type: "button", class: "m-fulfillment-time__arrow-btn u-btn-unstyled", onClick: onPrev, "aria-label": translate(t.views.pagination.previous), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { class: "icon-btn icon-left", "aria-hidden": "true" }))),
            items.map((item) => (h("li", { class: "m-fulfillment-time__slider-item", key: item.title + item.description },
                h("button", { type: "button", onClick: (event) => setActive(item, event.currentTarget), class: `m-fulfillment-time__day u-btn-unstyled ${isActive(item) ? "is-active" : ""} ${isDisabled(item) ? "is-disabled" : ""}` },
                    h("span", { class: "u-text-bold u-mb1" }, item.title),
                    h("span", { class: "u-text-nowrap" },
                        item.description,
                        isConflicting(item) && (h("i", { class: "icon-btn icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))))))),
            h("li", { class: `m-fulfillment-time__slider-arrow m-fulfillment-time__slider-arrow--next ${nextArrowVisible ? "is-visible" : ""}`, key: "next-arrow" },
                h("button", { type: "button", class: "m-fulfillment-time__arrow-btn u-btn-unstyled", onClick: onNext, "aria-label": translate(t.views.pagination.next), "aria-controls": "fulfillment-time-days-slider" },
                    h("i", { class: "icon-btn icon-right", "aria-hidden": "true" }))))));
});
const HoursList = observer((props) => {
    const { listRef, modal } = props;
    // Scroll to selected hour item on first render (when modal shown)
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            setTimeout(() => {
                selectedHourItem.scrollIntoView({
                    behavior: "instant",
                    block: "center",
                });
            }, 150); // 0.15s is a hardcoded value in boostrap 3 styles for modal fade transition duration
        }
    }, [listRef]);
    // Scroll to selected hour item when active date change
    useEffect(() => {
        var _a;
        const selectedHourItem = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(".m-radio.is-active");
        if (selectedHourItem) {
            selectedHourItem.scrollIntoView({
                behavior: "instant",
                block: "center",
            });
        }
    }, [listRef, modal.activeDate]);
    return (h("ul", { ref: listRef, class: "m-fulfillment-time__list u-list-unstyled", "aria-live": "polite", "aria-label": translate(t.orders.form.choose_time), tabIndex: -1, "data-field-id": "fulfillmentTime.value", "data-field-type": "radioGroup" },
        modal.dateTimeItems.map((item) => (h("li", { "data-field-id": "fulfillmentTime." + item.stringValue },
            h(RadioFieldOption, { name: "fulfillment-time-hour", value: item.stringValue, label: h(Fragment, null,
                    item.title,
                    modal.isConflictingItem(item) && (h("i", { class: "icon-base icon-alert-sign text-warning u-ml1", "aria-hidden": "true" }))), isActive: modal.isActiveValue(item), isDisabled: modal.isDisabledItem(item), childrenCssClass: "m-radio__children--align-to-label", onChange: () => modal.setValue(item.value), onBlur: () => null },
                h(Fragment, null,
                    item.description !== undefined && (h("span", null, item.description)),
                    modal.isConflictingItem(item) && (h(Alert, { type: "warning", extraClasses: "u-my0", message: h(Fragment, null,
                            h("p", null, translate(t.orders.form.conflicting_products_warning)),
                            h("ul", null, item.conflictingProducts.map((productName) => (h("li", null, productName))))) }))))))),
        modal.dateTimeItems.length === 0 && (h(Alert, { type: "danger", message: translate(t.orders.form.unavailable_date) }))));
});
export default observer(FulfillmentTimeModal);
