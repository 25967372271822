import { FieldTest, LocalStorage, StorageManagerFactory, } from "restaumatic-client";
/**
 * List of experiments to apply.
 */
const experiments = [];
export default {
    init() {
        const storageManagerFactory = new StorageManagerFactory({
            storage: LocalStorage.create(),
        });
        const fieldTest = new FieldTest({
            fieldTestsStorageManager: storageManagerFactory.forFieldTests(),
            experiments,
        });
        // Apply all experiments
        fieldTest.applyExperiments();
    },
};
