import Popover from "bootstrap/js/dist/popover";
import Device from "./Device";
/**
 * Universal popover closing.
 */
function initDismiss(className) {
    document.addEventListener("click", (e) => {
        const dismissBtnEl = e.target;
        if (dismissBtnEl && dismissBtnEl.classList.contains(className)) {
            const popoverEl = dismissBtnEl.closest(".popover");
            const popoverTrigger = document.querySelector(`[aria-describedby="${popoverEl.id}"]`);
            if (!popoverEl || !popoverTrigger)
                return;
            const popoverInstance = Popover.getOrCreateInstance(popoverTrigger);
            popoverInstance === null || popoverInstance === void 0 ? void 0 : popoverInstance.hide();
        }
    });
}
/**
 * Popover instance initialization.
 */
function initPopover(selector) {
    document.querySelectorAll(selector).forEach((el) => {
        if (!(el instanceof HTMLElement))
            return;
        initPopoverElement(el);
    });
}
/**
 * Initializing single popover by specific HTML element. Used e.g. in preact popover wrapper `js/components/PreactPopover.tsx`
 */
export function initPopoverElement(element) {
    if (!(element instanceof HTMLElement))
        return;
    const popoverEl = element;
    const placement = popoverEl.dataset.placement || popoverEl.dataset.bsPlacement;
    const popoverContainerEl = popoverEl.classList.contains("js-popover-container")
        ? popoverEl
        : popoverEl.closest(".js-popover-container");
    const container = popoverEl.dataset.container ||
        popoverContainerEl ||
        popoverEl.dataset.bsContainer;
    const classes = popoverEl.dataset.classes || popoverEl.dataset.bsCustomClass;
    const trigger = popoverEl.dataset.trigger || popoverEl.dataset.bsTrigger;
    const dismissBtn = popoverEl.dataset.dismissBtn;
    const contentSelector = popoverEl.dataset.target;
    // Require `data-target` attribute defined and `js-content` HTML element inside target element
    const contentTemplate = popoverEl.dataset.templateContent;
    const contentEl = document.querySelector(contentSelector);
    const contentHTML = () => {
        if (contentTemplate) {
            // @ts-expect-error querySelector on shadow DOM
            let dynamicContentEl = contentEl.content.querySelector(".js-content");
            dynamicContentEl.innerHTML = contentTemplate;
        }
        return (contentEl === null || contentEl === void 0 ? void 0 : contentEl.innerHTML) || popoverEl.dataset.bsContent || "";
    };
    const title = () => {
        if (dismissBtn) {
            return `<button type="button" class="${popoverEl.dataset.dismissBtn} js-dismiss-popover">&times;</button> ${popoverEl.dataset.title || popoverEl.dataset.bsTitle || ""}`;
        }
        return popoverEl.dataset.title || popoverEl.dataset.bsTitle || "";
    };
    new Popover(element, {
        title: title,
        content: contentHTML,
        placement: placement || "top",
        container: container || false,
        customClass: classes || "",
        trigger: trigger || "click",
        html: true,
        sanitize: false,
    });
    popoverEl.addEventListener("click", (e) => {
        e.preventDefault();
        if (Device.browser.isSafari() && trigger === "focus") {
            popoverEl.focus();
        }
    });
}
/**
 * Hide and destroy single element popover
 */
export function disposePopover(element) {
    if (!(element instanceof HTMLElement))
        return;
    const popover = Popover.getOrCreateInstance(element);
    popover.dispose();
    // workaround for https://github.com/twbs/bootstrap/issues/37474
    // @ts-ignore - `_activeTrigger` exist in private API of Tooltip instance
    popover._activeTrigger = {};
    // @ts-ignore - `_element` exist in private API of Tooltip instance
    popover._element = document.createElement("noscript");
}
/**
 * Manual popover closing.
 *
 * It is workaround to close popover on click at specific element to resolve visbility/position issues (e.g. collapsible promotions section in Simple or Solej, where details popover remains open after closing the section)
 *
 * TODO: Do it right way (maybe close at every click outside the popover?)
 */
function initManualClose(selector) {
    const elements = document.querySelectorAll(selector);
    elements.forEach((item) => {
        item.addEventListener("click", () => {
            const openPopovers = document.querySelectorAll(".popover.show");
            openPopovers.forEach((el) => {
                const popoverTrigger = document.querySelector(`[aria-describedby="${el.id}"]`);
                const popoverInstance = Popover.getOrCreateInstance(popoverTrigger);
                popoverInstance.hide();
            });
        });
    });
}
/**
 *  Popover (Bootstrap 5 popover plugin).
 *
 * @link https://getbootstrap.com/docs/5.2/components/popovers/
 */
export default {
    init: function () {
        initPopover(".js-popover");
        initDismiss("js-dismiss-popover");
        initManualClose(".js-close-popovers");
        // Fix showing popover over modal & popover instance issues after opening modal while popover is visible
        document.addEventListener("show.bs.modal", () => {
            const openPopovers = document.querySelectorAll(".popover.show");
            openPopovers.forEach((el) => {
                const popoverTrigger = document.querySelector(`[aria-describedby="${el.id}"]`);
                const popoverInstance = Popover.getOrCreateInstance(popoverTrigger);
                popoverInstance.hide();
            });
        });
    },
};
